$primary: #1c1c1c !default; $secondary: #f9fcff !default; $tertiary: #1d6d6d !default; $quaternary: #cb3727 !default; $header_background: #cb3727 !default; $header_primary: #ffffff !default; $highlight: #e6cb37 !default; $danger: #d05454 !default; $success: #71bd9f !default; $love: #c16ad7 !default; $selected: #d2edf3 !default; $hover: #d2edf3 !default;  $public_image_path: "https://ghdev.cnvc.org/images";  @import "common/foundation/variables"; @import "common/foundation/mixins"; table.group-reports {
  width: 100%;
  table-layout: fixed;

  th:first-child {
    width: 30%;
  }
  th:nth-child(2) {
    width: 60%;
  }
  th:last-child {
    width: 20%;
    text-align: right;
  }
  tbody tr td {
    padding: 0.5em;
    &:first-child {
      font-size: $font-up-1;
    }
    &:last-child {
      text-align: right;
    }
  }
}

.https-warning {
  color: var(--danger);
}

.query-editor {
  margin-bottom: 15px;

  .panels-flex {
    display: flex;
    height: 400px;
    border: 1px solid var(--primary-very-low);
  }
  &.no-schema {
    .editor-panel {
      width: 100%;
    }
    .right-panel {
      width: 0;
      button.unhide {
        position: absolute;
        margin-left: -53px;
        z-index: 1;
      }
    }
  }

  .editor-panel {
    flex-grow: 1;
    .ace-wrapper {
      position: relative;
      height: 100%;
      width: 100%;
    }
    .ace_editor {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
  .right-panel {
    flex-shrink: 0;
    flex-grow: 0;
    width: 345px;
    .schema {
      border-left: 1px solid var(--primary-low);
      height: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      color: var(--primary-high);
      font-size: var(--font-down-1);
      position: relative;

      .schema-search {
        padding: 0.5em;
        position: sticky;
        background-color: var(--secondary);
        top: 0px;
        z-index: 1;
      }

      .schema-table-name {
        font-weight: bold;
        border-bottom: 1px solid var(--primary-low);
        padding-left: 5px;
        .fa {
          width: 6px;
        }
      }
      ul {
        list-style-type: none;
        margin: 0;
      }
      dl {
        margin: 0;
      }
      dl > div > * {
        padding-top: 2px;
        padding-bottom: 2px;
      }
      dl > div {
        clear: both;
      }
      dt {
        display: inline-block;
        vertical-align: text-top;
        overflow-wrap: break-word;
        width: 110px;
        margin-left: 5px;
        &.sensitive {
          color: var(--danger);
        }
      }
      dd {
        display: inline-block;
        vertical-align: text-top;
        width: 110px;
        color: var(--tertiary);
        margin: 0;
        padding-left: 7px;
        border-left: 1px dotted var(--primary-low-mid);

        .schema-typenotes {
          color: var(--primary-medium);
          font-style: italic;
        }
        .popup-info {
          color: var(--primary-medium);

          .popup {
            display: none;
          }
          &:hover .popup {
            display: block;
          }
          &:focus .popup {
            display: block;
          }

          .popup {
            width: 180px;
            padding: 4px;
            position: relative;
            border: 1px solid;
            background: var(--secondary);
            padding-right: calc(5px + 0.5em);
          }

          .popup ol {
            padding: 0px;
            position: relative;
            list-style: none;
            > li:before {
              content: attr(value) ": ";
            }
          }
        }
      }
    }
  }
  .grippie {
    cursor: nwse-resize;
    clear: both;
    font-size: $font-down-2;
    -webkit-user-select: none;
    color: var(--primary);
    text-align: right;
    background: var(--primary-very-low);
    border: 1px solid var(--primary-very-low);
    .d-icon {
      transform: rotate(90deg);
    }
  }
}

.query-edit {
  > .name {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    h1 {
      display: inline-block;
      margin: 0 0.5em 0 0;
      color: var(--primary);
      a {
        color: currentColor;
      }
    }
  }
  button.previous {
    margin-right: 0.5em;
    .d-icon {
      margin-left: -0.15em; // fixing fontawesome horizontal alignment
    }
  }
  .name-text-field {
    width: 100%;
  }
  .name input,
  .desc textarea {
    width: 100%;
    margin: 0;
  }
  &:not(.editing) .desc {
    margin: 10px 0;
  }
  .groups {
    margin: 10px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    @include breakpoint("tablet") {
      flex-direction: column;
      align-items: flex-start;
    }
    .label {
      margin-right: 10px;
      color: var(--primary-high);
    }
    .name {
      display: inline;
    }

    .select-kit.multi-select {
      @include breakpoint("tablet") {
        width: 360px;
      }
    }
  }
}

.query-run {
  margin-top: 25px;
  .query-plan {
    display: inline-block;
    margin-left: 15px;
  }
}

.clear {
  clear: both;
}

.schema-title {
  display: block;
  margin: auto;
}

.query-params {
  border: 1px solid var(--header_primary-medium);
  .params-form {
    margin: 5px;
    html.desktop-view & {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
  .param > input,
  .param > .select-kit {
    margin: 9px;
  }
  .invalid input {
    background-color: var(--danger-low);
  }
  .invalid .ac-wrap {
    background-color: var(--danger-low);
  }
  .param {
    display: flex;
    align-items: center;
    flex: 0;
    .ac-wrap {
      display: inline-block;
      input {
        width: 100px !important; // override an inline style
      }
    }
    input,
    .select-kit {
      width: auto;
      max-width: 250px;
    }
  }
}

.query-list,
.query-create,
.query-edit,
.query-results,
.query-params,
.https-warning {
  margin: 10px 0;
}

.query-create {
  display: flex;
  input {
    margin-right: 0.5em;
    margin-bottom: 0;
  }
}

.query-results {
  section {
    width: 100%;
    overflow: auto;
    max-height: 1000px;
  }
  table {
    width: 100%;
    margin-top: 10px;
    td {
      padding: 8px;
    }
  }
  thead {
    th {
      position: sticky;
      top: 0;
      color: var(--primary);
      background: var(--primary-low);
      z-index: z("base");
    }
  }
}

.query-list {
  display: flex;
  align-items: flex-start;
  .btn-left {
    margin-right: 0.5em;
  }
  .btn-right {
    margin-left: auto;
    margin-right: 0.5em;
  }
  li.none {
    display: none;
  }
  .import-btn {
    display: flex;
  }
}

.recent-queries {
  thead {
    .created-by {
      width: 15%;
    }
    .group-names {
      width: 15%;
      .group-names-header {
        position: absolute;
        bottom: 8px;
        left: 6px;
      }
    }
    .created-at {
      width: 15%;
    }
    .heading {
      position: relative;
      color: var(--primary-medium);
      padding: 50px 0px 0px 0px;
      th.sortable {
        font-weight: normal;
      }
      .heading-toggle {
        bottom: 0;
        position: absolute;
        display: inline-block;
      }
    }
  }
  .query-row {
    a {
      width: 100%;
    }
    .query-name {
      color: var(--primary);
    }
    .query-desc {
      display: block;
      color: var(--primary-high);
    }
    .query-created-by {
      color: var(--primary-high);
    }
    .query-group-names {
      color: var(--tertiary);
      a {
        display: inline;
      }
    }
    .group-names {
      @include breakpoint("tablet") {
        text-align: right;
      }
    }
    .query-created-at {
      color: var(--primary-medium);
    }
  }
  .query-row:hover {
    background-color: inherit;
  }
  tr a {
    color: inherit;
    cursor: pointer;
    display: inline-block;
  }
  .no-search-results {
    color: var(--primary-medium);
  }
}

.result-info {
  float: left;
}
.result-about {
  color: var(--primary-high);
  float: right;
}
.result-explain {
  padding-top: 1em;
  margin-bottom: 0px;
}
.result-post-link {
  display: block;
  color: inherit !important;
}
.result-json {
  display: flex;
}

.result-json-value {
  flex: 1;
  margin-right: 0.5em;
  max-width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.explorer-pad-bottom {
  margin-bottom: 200px;
}

.share-report {
  cursor: pointer;

  label {
    color: var(--primary-high);
  }
  input {
    margin-right: 4px;
  }
  .popup {
    background-color: var(--secondary);
    position: absolute;
    box-shadow: var(--shadow-card);
    padding: 12px;
    z-index: 1;
  }
}

.left-buttons,
.right-buttons {
  display: flex;
  align-items: flex-start;
}

.left-buttons .btn {
  margin-right: 0.5em;
}

.right-buttons .btn {
  margin-left: 0.5em;
}

.query-group-bookmark {
  &.bookmarked .d-icon {
    color: var(--tertiary);
  }
}
